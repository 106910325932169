<template>
  <v-container class="pa-0 fill-height" fluid>
    <v-row no-gutters class="fill-height">
      <Sidebar />
      <v-col
        primary
        cols="12"
        sm="6"
        md="7"
        class="d-inline light-green lighten-5"
      >
        <div
          class="
            pa-6 pa-sm-4 pa-md-12
            fill-height
            d-flex
            flex-direction:
            column
            justify-center
          "
        >
          <v-container class="pa-0 fill-height" fluid>
            <v-row no-gutters class="fill-height">
              <v-col primary cols="12" sm="9" md="6" class="ma-auto">
                {{ $t("duplicateTab") }}
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DuplicateTab",
  components: {
    Sidebar: () => import("@/components/PublicAside"),
  },
};
</script>
